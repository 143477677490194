<template>
    <div class="container m-auto my-3">
        <component :is="currentComponent" :title="title"></component>
    </div>
</template>

<script>
import {onMounted, ref, computed} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import NetPromoterScore from '@/components/forms/net-promoter-score/NetPromoterScore'
import NetPromoterScoreWithComment from '@/components/forms/net-promoter-score/NetPromoterScoreWIthComment'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'NpsView',
    components: {NetPromoterScore, NetPromoterScoreWithComment},
    setup(_, { root }) {
        const { formTitle } = useGetters(['formTitle'])
        const { setFormTitle, setThankYouRedirectQueryParams } = useActions(['setFormTitle', 'setThankYouRedirectQueryParams'])
        const route = root.$route
        const query = route?.query
        const tenantId = ref(query?.tenant || query?.ti)
        const title = ref(formTitle || query?.t || 'Patient Survey')
        const isExternal = ref(query.ext)
        const formSetId = ref(query.fsi)
        const routeType = ref(route.params?.routeType)
        const defaultComponent = 'NetPromoterScore'
        const components = ref([{
            route: 'nps',
            name: 'NetPromoterScore'
        }, {
            route: 'npsc',
            name: 'NetPromoterScoreWithComment'
        }])

        //#region computed properties
        const currentComponent = computed(() => {
            const component = components.value.find(c => c.route === routeType.value)
            return component?.name || defaultComponent
        })
        //#endregion

        //#region lifecycle callbacks
        onMounted(() => {
            setFormTitle(title.value)
            setThankYouRedirectQueryParams({
                fsi: formSetId.value,
                tenant: tenantId.value,
                // practiceName: name
            })
        })
        //#endregion

        return {
            title,
            isExternal,
            currentComponent
        }
    }
}
</script>
